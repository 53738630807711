@use 'sass:math';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/utils';

.HorizontalNavigation {
  $max-width: tokens.$bpk-one-pixel-rem * 1224;

  /*
    When the &__container part detaches from it's original place and fixes itself to the top,
    The outer &__height_holder remains in its original position (scrolled out of view),
    Without it, the component will collapse, resulting in the page content visibly jumping up.
   */
  &__height_holder {
    height: tokens.$bpk-one-pixel-rem * 48;
  }

  &__container {
    display: flex;
    height: tokens.$bpk-one-pixel-rem * 48;
    background-color: tokens.$bpk-surface-default-day;
    white-space: nowrap;
    box-shadow: 0 tokens.$bpk-one-pixel-rem 0 0 tokens.$bpk-line-day;

    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__fixed {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    // stylelint-disable-next-line prettier/prettier
    box-shadow: 0 math.div(tokens.bpk-spacing-md(), 6) tokens.bpk-spacing-md() * 0.5 rgba(tokens.$bpk-background-dark-color, 0.25);
  }

  &__static {
    position: static;
  }

  &__hidden {
    display: none;
  }

  &__items {
    display: flex;
    width: $max-width;
    margin: 0 auto;

    // Hide scrollbar
    overflow-y: hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Chrome Safari */
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    display: flex;
    padding: 0 tokens.bpk-spacing-lg();
    justify-content: center;
    align-items: center;
    flex: none;
    flex-shrink: 0;
    color: tokens.$bpk-text-secondary-day;
    text-decoration: none;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        box-shadow: 0 (tokens.$bpk-one-pixel-rem * -4) 0
          (tokens.$bpk-one-pixel-rem * -1) tokens.$bpk-surface-highlight-day
          inset;
      }
    }

    &-selected {
      color: tokens.$bpk-horizontal-nav-bar-selected-color;
      box-shadow: 0 (tokens.$bpk-one-pixel-rem * -4) 0
        (tokens.$bpk-one-pixel-rem * -1)
        tokens.$bpk-horizontal-nav-bar-selected-color inset;

      &:hover {
        box-shadow: 0 (tokens.$bpk-one-pixel-rem * -4) 0
          (tokens.$bpk-one-pixel-rem * -1)
          tokens.$bpk-horizontal-nav-bar-selected-color inset;
      }
    }
  }

  &__button {
    display: flex;
    margin-left: auto;
    align-items: center;

    @include breakpoints.bpk-breakpoint-mobile {
      display: none;
    }

    @include utils.bpk-rtl {
      margin-right: auto;
      margin-left: tokens.$bpk-one-pixel-rem * 24;
    }

    @media (max-width: $max-width) {
      margin-right: tokens.$bpk-one-pixel-rem * 24;
    }

    &-hidden {
      display: none;
    }
  }

  &__arrow-up-icon {
    margin-left: 4 * tokens.$bpk-one-pixel-rem;

    @include utils.bpk-rtl {
      margin-right: 4 * tokens.$bpk-one-pixel-rem;
      margin-left: 0;
    }
  }
}
